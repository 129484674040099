import { BetManager, constants, useApplicationState, useHighlightedOutcome } from '@apollo/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { BET_EVENT_TYPE, STATUS } from '@apollo/core/src/constants';
import Odds from '../../../shared/components/Odds';
import { FAV_MARKET_ODD_TITLE, RACE_STATUS, SPECIAL_MARKETS } from '../../../core/constants';
import { oddsAnimation } from '../../../core/utils';
import { BonusSelector } from '../../../state/Bonus/Bonus';
import SpecialOddsName from './SpecialOddsName';
import PromoOdds from '../../../shared/components/Odds/PromoOdds';
import CalculateOddsBoost from '../../../state/Bonus/OddsBoostCalculator';

const T_5MINUTE = 60 * 5;

const ANIMATION_TIMEOUT_MS = 3000;

const RaceOutcome = ({ className, meet, race, runner, market, outcome, isFavourite = false }) => {
  const { layout } = useApplicationState();
  const { selected, placed, editing, loading, failed, succeeded } = useHighlightedOutcome(
    outcome.outcomeId,
  );

  const prevOddsRef = useRef(_.toNumber(outcome.odds));
  const resetDirectionClassTimerRef = useRef(null);
  const [directionClassName, setDirectionClassName] = useState(null);

  const selectedPromoOdds = BonusSelector.getPromoOddsByEventIdAndOutcomeId({
    eventId: race.raceId,
    outcomeId: outcome.outcomeId,
  })
    // if multiple promo odds defined for the same outcome then take the highest
    .sort((a, b) => b.getFinalOdds() - a.getFinalOdds())[0];

  const promoOdds = useMemo(() => selectedPromoOdds?.getFinalOdds(), [selectedPromoOdds]);

  const selectedFavOddsBoost = BonusSelector.getFavOddsBoost({
    eventId: race.raceId,
    outcomeId: outcome.outcomeId,
    raceTypeId: race.raceType?.id,
    marketType: market.type,
    country: race.country,
  })?.[0];

  const favBoostedOdds = useMemo(() => {
    if (!isFavourite || !selectedFavOddsBoost) {
      return null;
    }
    const timeDiff = new Date(race.startTime).getTime() - new Date().getTime();
    const secondsDiff = timeDiff / 1000;
    if (secondsDiff > T_5MINUTE) {
      return null;
    }
    return CalculateOddsBoost(outcome.odds);
  }, [isFavourite, selectedFavOddsBoost, race.startTime, outcome.odds]);

  useEffect(() => {
    const newOdds = _.toNumber(outcome.odds);
    const prevOdds = prevOddsRef.current;
    const dOdds = newOdds - prevOdds;
    prevOddsRef.current = newOdds;

    if (!dOdds || !!market.oddsText) {
      return;
    }

    // window.clearTimeout(resetDirectionClassTimerRef.current);
    setDirectionClassName(dOdds > 0 ? 'up' : 'down');
    resetDirectionClassTimerRef.current = window.setTimeout(() => {
      setDirectionClassName(null);
    }, ANIMATION_TIMEOUT_MS);
    return () => {
      window.clearTimeout(resetDirectionClassTimerRef.current);
    };
  }, [outcome.odds, market.oddsText]);

  const isDisabled = useMemo(
    () => ![STATUS.ACTIVE, RACE_STATUS.OPEN].includes(race.status)
      || !Number.parseFloat(outcome.odds)
      || market.status === constants.STATUS.SUSPENDED,
    [race.status, outcome.odds, market.status],
  );

  const clickHandler = useCallback(
    (e) => {
      if (layout.mobileDevice && !selected) {
        oddsAnimation(
          e.target.classList.contains('event__outcome') || e.target.classList.contains('btn')
            ? e.target
            : e.target.parentNode,
        );
      }

      BetManager.selectBet({
        betEventType: BET_EVENT_TYPE.RACING,
        event: race,
        eventId: race.raceId,
        marketTypeId: market.type,
        marketId: market.marketId,
        outcomeId: outcome.outcomeId,
        // todo
        // Only a single bet of the same group can exist in the betslip
        // We need to identify if betGroups are required for Racing
        betGroups: [0],
        isNew: true,
      });

      e.stopPropagation();
    },
    [meet, race, runner, market, outcome, layout.mobileDevice, selected],
  );

  const outcomeClassName = React.useMemo(
    () => cx(
      className,
      'event__outcome',
      'event__outcome--race',
      directionClassName ? `event__outcome--${directionClassName}` : '',
      promoOdds ? 'odds__promo-odds highlighted' : '',
      {
        disabled: isDisabled,
        selected,
        editing,
        placed,
        failed,
        loading,
        succeeded,
      },
    ),
    [
      className,
      selected,
      editing,
      placed,
      failed,
      loading,
      succeeded,
      isDisabled,
      runner?.name,
      directionClassName,
      promoOdds,
    ],
  );

  const isFav = market.type === 'FAV_OUT' && !outcome.odds;

  const renderOdds = useMemo(() => {
    if (isFav) {
      return outcome.selection;
    }

    if (favBoostedOdds) {
      return <PromoOdds odds={outcome.odds} promoOdds={favBoostedOdds} decimal={2} />;
    }

    if (promoOdds) {
      return <PromoOdds odds={outcome.odds} promoOdds={promoOdds} decimal={2} />;
    }

    return (
      <span>
        <Odds value={outcome.odds} decimal={2} minDecimal={2} />
      </span>
    );
  }, [isFav, outcome.odds, outcome.selection, promoOdds, favBoostedOdds]);

  return (
    <>
      <div
        key={outcome.odds}
        className={outcomeClassName}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(!isDisabled && { onClick: (e) => clickHandler(e) })}
      >
        {SPECIAL_MARKETS[market.type] && market.oddsText ? (
          <span className={cx('odds')}>
            <SpecialOddsName market={market} />
          </span>
        ) : (
          <span className={cx('odds', { isFav })} title={isFav ? FAV_MARKET_ODD_TITLE : ''}>
            {renderOdds}
          </span>
        )}
        {directionClassName ? (
          <span className={`${directionClassName} AIcon-angle-${directionClassName}`} />
        ) : null}
      </div>
      {race.status === RACE_STATUS.PAYING && !isFav ? (
        <div className={`favorite favorite--${outcome?.status?.toLowerCase()}`}>
          {outcome?.status}
          {' '}
          {outcome?.dhRation ? `(${outcome?.dhRation})` : ''}
        </div>
      ) : null}
    </>
  );
};

export default RaceOutcome;
