import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { isInactive, isSuspended, SiteConfigManager, useApplicationState } from '@apollo/core';
import qs from 'query-string';
import Theme from 'themeSource/custom';
import useEventDetails from './useEventDetails';
import useScrollToTop from './useScrollToTop';
import DetailPageHeader from './DetailPageHeader';
import DetailPageMarketTypesMapping from './DetailPageMarketTypesMapping';
import SportsbookFilter from '../SportsbookFilter/SportsbookFilter';
import SportsbookFilterOption from '../SportsbookFilter/SportsbookFilterOption';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import { sportBetTypes, sportService } from '../../../core/constants';
import SportLoader from '../SportLoader';
import BonusBanner from '../../../shared/components/Bonus/BonusBanner';
import { scrollToTop } from '../../../core/utils';

const { ARCHIVED } = sportService;

const scrollToTopConfig = {
  mountMode: true,
  parentMode: true,
  unmountMode: true,
};

const DetailPageEvent = ({ event, suspended, disabled }) => {
  const { marketGroups, activeBetType, setActiveBetType } = useEventDetails(event);

  const enableSameGameMultiBets = SiteConfigManager.getConfig(
    'config.config.sportsbook.enableSameGameMultiBets',
  );

  const ThemeDetailPageHeader = Theme.DetailPageHeader || DetailPageHeader;

  const { search } = useLocation();
  const translate = useTranslate();
  const { layout } = useApplicationState();
  const { elementRef } = useScrollToTop(scrollToTopConfig);
  const isEventSuspended = suspended || isSuspended(event);
  const isEventDisabled = disabled || isInactive(event);

  const isSgmEnabled = useMemo(
    () => enableSameGameMultiBets && event?.isSgmEnabled,
    [enableSameGameMultiBets, event],
  );

  const options = React.useMemo(
    () => [
      {
        text: translate(`provider_pinnacle${layout.mobileDevice ? '_mobile' : ''}`),
        value: sportBetTypes.Single,
      },
      {
        text: (
          <span className='sgm-provider'>
            {translate(`provider_pinnacle_same_game_multi${layout.mobileDevice ? '_mobile' : ''}`)}
          </span>
        ),
        value: sportBetTypes.SameGameMulti,
      },
    ],
    [translate, layout.mobileDevice],
  );

  React.useEffect(() => {
    const { isSgm } = qs.parse(search);
    if (isSgm) {
      setActiveBetType(sportBetTypes.SameGameMulti);
      window.history.replaceState({}, '', window.location.href.replace('&isSgm=true', ''));
    }
  }, []);

  React.useEffect(() => {
    scrollToTop({ isMobileDevice: layout.mobileDevice });
  }, [activeBetType, layout.mobileDevice]);

  return (
    <div className='sport-markets animate-fade-left-in' ref={elementRef}>
      <ThemeDetailPageHeader event={event} />
      {isSgmEnabled && (
        <SportsbookFilter
          options={options}
          onChange={setActiveBetType}
          value={activeBetType}
          className='sFilter sport-filter--provider sports-filter--tournament'
          ItemComponent={SportsbookFilterOption}
        />
      )}
      <div className='sBlock__content sportDetails'>
        <div className='sBlock__content__element'>
          {event && !event.loading ? (
            <>
              <BonusBanner event={event} isExpanded />
              {event.sportService !== ARCHIVED ? (
                <DetailPageMarketTypesMapping
                  suspended={isEventSuspended}
                  disabled={isEventDisabled}
                  betType={activeBetType}
                  marketGroups={marketGroups}
                />
              ) : null}
            </>
          ) : (
            <SportLoader />
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailPageEvent;
