import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import React from 'react';
import { buildCdnUrl } from '../../core/utils';
import useTranslate from '../../shared/components/I18n/Interpreter';

const PromotionItem = ({ promotion }) => {
  const t = useTranslate();
  const { image_url, mobile_image_url, short_content, title, action_url, id } = promotion;

  return (
    <div className='promotion-item'>
      {image_url && (
        <Link className='promotion-item__image' to={`/promotions/${id}`}>
          <img src={buildCdnUrl(image_url, 'LOGOS_CDN_URL')} alt='' loading='lazy' />
        </Link>
      )}

      {mobile_image_url && (
        <Link
          className='promotion-item__image promotion-item__image--mobile'
          to={`/promotions/${id}`}
        >
          <img src={buildCdnUrl(mobile_image_url, 'LOGOS_CDN_URL')} alt='' loading='lazy' />
        </Link>
      )}

      <div className='promotion-item__content'>
        <h3 className='promotion-item__title'>{title}</h3>

        <div
          className='promotion-item__info'
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(short_content, { FORCE_BODY: true }),
          }}
        />

        <Link
          className='btn btn-sm btn-primary-outline promotion-item__link'
          to={`/promotions/${id}`}
        >
          {t('read_more')}
        </Link>
      </div>
    </div>
  );
};

export default PromotionItem;
