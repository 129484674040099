import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useTranslate from '../../components/I18n/Interpreter';
import HeaderLogoItem from '../../components/Header/HeaderMenu/HeaderLogoItem';

const AuthBlock = ({ allowClose = true, children }) => {
  const { pathname } = useLocation();
  const t = useTranslate();

  return (
    <div className='auth-block-modal'>
      <div className='auth-block-modal__top-block'>
        <HeaderLogoItem location='/' />
      </div>

      <div className='auth-block-modal__container'>
        {allowClose && (
          <Link className='icon__close__wrapper' to={pathname}>
            <i className='icon__close' title={t('close')} />
          </Link>
        )}
        {children}
      </div>
    </div>
  );
};

export default AuthBlock;
