import React from 'react';
import { SettingsManager } from '@apollo/core';
import { clientProfileRoutes } from '@apollo/routing';
import AcceptOddsSettings from './AcceptOddsSettings';
import { defaultSettings } from '../../Betslip/constants';
import Label from '../../../shared/components/I18n/Label';
import ThemeSettings from './ThemeSettings';
import QuickBetSettings from './QuickBetSettings';
import ClientProfileLinks from '../ClientProfileLinks';
import { LocalConfig } from '../../../core/utils';

const { settings } = clientProfileRoutes;

const BetslipSettings = () => {
  const theme = LocalConfig.get('themeContrast', defaultSettings.theme);

  const setDefaultAcceptChanges = () => SettingsManager.updateSettings({ ...defaultSettings, theme });

  return (
    <div className='main__page__account__block'>
      <div className='main__page__account__block__title'>
        <div className='name'>
          <Label message='betslip_settings' />
        </div>

        <ClientProfileLinks tabs={[]} availableRoutes={[]} parentRoute={settings} />
      </div>
      <div className='main__page__account__block__content settings'>
        <div className='wrapper wrapper--settings settings-content'>
          <div className='details-profile details-profile--betslip'>
            <ThemeSettings />
            <QuickBetSettings />
            <AcceptOddsSettings />
            {/* <OddsFormatSettings /> */}

            <div className='settings-content__footer'>
              <div className='block block__bGroup'>
                <button type='button' className='bBasePrimary' onClick={setDefaultAcceptChanges}>
                  <Label message='restore' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetslipSettings;
