import React from 'react';
import PropTypes from 'prop-types';
import { SiteConfigManager } from '@apollo/core';
import { isDef } from '../../core/utils';

const OutcomeStatTracker = ({ metadata, outcomeStatus, interimStatus }) => {
  const isShowingBadge = SiteConfigManager.getConfig(
    'config.config.general.showOutcomeStatTracker',
    false,
  );

  if (!isShowingBadge || !isDef(metadata)) {
    return null;
  }

  const { targetStatValue, currentStatValue } = metadata;

  if (!isDef(targetStatValue) || !isDef(currentStatValue)) {
    return null;
  }

  const checkPoints = [<li className='outcome-stat-tracker__check-point' />];

  const currentCheckPoint = (
    <li
      key='current'
      className='outcome-stat-tracker__check-point outcome-stat-tracker__check-point--is-current'
    >
      <span>{currentStatValue}</span>
    </li>
  );

  const targetCheckPoint = (
    <li
      key='current'
      className='outcome-stat-tracker__check-point outcome-stat-tracker__check-point--is-target'
    >
      <span>{targetStatValue}</span>
    </li>
  );

  if (isDef(currentStatValue)) {
    if (currentStatValue === targetStatValue) {
      checkPoints.push(currentCheckPoint);
    } else if (currentStatValue > targetStatValue) {
      checkPoints.push(targetCheckPoint);
      checkPoints.push(currentCheckPoint);
    } else {
      checkPoints.push(currentCheckPoint);
      checkPoints.push(targetCheckPoint);
    }
  }

  return (
    <ol
      className={[
        'outcome-stat-tracker__progress-bar',
        isDef(outcomeStatus)
          ? `outcome-stat-tracker__progress-bar--is-${`${outcomeStatus}`.toLocaleLowerCase()}`
          : '',
      ].join(' ')}
    >
      {checkPoints}
    </ol>
  );
};

OutcomeStatTracker.propTypes = {
  // eslint-disable-next-line react/require-default-props
  outcomeStatus: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  interimStatus: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  metadata: PropTypes.shape({
    targetStatValue: PropTypes.number,
    currentStatValue: PropTypes.number,
  }),
};

export default OutcomeStatTracker;
