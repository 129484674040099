import React, { useCallback, useEffect, useMemo } from 'react';
import { SiteConfigManager, useApplicationState } from '@apollo/core';
import { SIDEBAR_ITEMS } from '@apollo/core/src/constants';
import { map } from 'lodash';
import { SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import { useLocation } from 'react-router-dom';
import { COMPONENT_TYPES } from '@apollo/routing';
import cx from 'classnames';
import Label from '../../../shared/components/I18n/Label';
import SportsTreeItem from './SportsTreeItem';
import TopTournaments from './Tournaments/TopTournaments';
import TournamentList from './Tournaments/TournamentsList';
import { SportAllLink } from '../../../shared/components/Sport/SportLinks';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import { SidebarActions, useSidebarState } from '../../../state/Sidebar/Sidebar';
import { AnimatedDropdown } from '../../Animations/AnimatedDropdown';

const SportsSidebar = ({ isExpanded, handleExpand }) => {
  const { authenticated } = useApplicationState();
  const [sidebarState, sidebarDispatcher] = useSidebarState();
  const [sportsState] = useSportState();
  const t = useTranslate();
  const { pathname } = useLocation();

  const sports = SportSelector.selectSports();
  const prematchPath = SiteConfigManager.getExtraConfig(
    `pathList.${COMPONENT_TYPES.SPORT_PREMATCH}`,
  );

  const tournaments = SportSelector.selectTopTournaments({
    limit: 100,
    excludeOutright: false,
  });

  const sortedSports = useMemo(
    () => sports.filter((s) => s.totalCount > 0).sort((a, b) => a.name?.localeCompare(b.name)),
    [sports],
  );

  const toggleOpenedSportUrlName = useCallback(
    (sportUrlName) => {
      sidebarDispatcher({
        type: SidebarActions.TOGGLE_OPENED_SPORT,
        payload: {
          openedSportUrlName: sportUrlName,
        },
      });
    },
    [sidebarDispatcher],
  );

  const pathLength = pathname.split('/').length - 1;

  useEffect(() => {
    if (pathname.startsWith(prematchPath) && pathLength !== 1) {
      if (
        sportsState.selectedSportUrlName
        && sortedSports.find((s) => s.urlName === sportsState.selectedSportUrlName)
      ) {
        sidebarDispatcher({
          type: SidebarActions.SET_OPEN_SPORT_URL,
          payload: {
            openedSportUrlName: sportsState.selectedSportUrlName,
          },
        });
      }
    } else {
      sidebarDispatcher({
        type: SidebarActions.SET_OPEN_SPORT_URL,
        payload: {
          openedSportUrlName: null,
        },
      });
    }
  }, [pathname, prematchPath, sortedSports, sportsState.selectedSportUrlName, sidebarDispatcher]);

  const sectionTitleClasses = cx(
    'sidebar-tree__section-title',
    pathname.includes(prematchPath) ? 'active' : null,
  );

  return (
    <>
      <div className={sectionTitleClasses}>
        <SportAllLink className='sidebar-tree__section-title--link'>
          <i className='icons icon--sports' title={t('sport')} />
          <Label message='sport' className='name' />
        </SportAllLink>
        <span
          className={`icon AIcon-angle-${!isExpanded ? 'down' : 'up'}`}
          onClick={() => handleExpand(SIDEBAR_ITEMS.SPORTS)}
        />
      </div>

      <AnimatedDropdown isExpanded={isExpanded} className='sidebar-tree__section-data'>
        <div className='sidebar-tree__section sidebar-tree__section--top-tournaments'>
          <TopTournaments />
        </div>
        <div className='sidebar-tree__section sidebar-tree__section--sports'>
          <div className='sidebar-tree__section-title'>
            <Label message='all sports' className='name' />
          </div>

          {map(sortedSports, (s) => {
            if (
              s.urlName === sidebarState.openedSportUrlName
              || s.name === sidebarState.openedSportUrlName
            ) {
              const filteredTournaments = tournaments.filter(
                (t) => t.sportId === s.id && t.totalCount > 0,
              );

              return (
                <React.Fragment key={s.id}>
                  {/* <div className='Sidebar-tree__section-subtitle'>
                        <span className='icon AkIcon-globe-icon' />
                        <Label message='current' className='name' />
                      </div> */}
                  <SportsTreeItem
                    id={s.id}
                    sport={s}
                    toggleOpenedSportUrlName={toggleOpenedSportUrlName}
                    active={
                      s.urlName === sidebarState.openedSportUrlName
                      || s.name === sidebarState.openedSportUrlName
                    }
                    authenticated={authenticated}
                  />

                  {filteredTournaments.length > 0 ? (
                    <div key={`sidebar-group-${s.id}`} className='sidebar-tree__section-group'>
                      <TournamentList tournaments={filteredTournaments} />
                      <SportAllLink
                        className='sidebar-tree__section-item sidebar-tree__section-item--tournament sidebar-tree__section-item--all'
                        sportUrlName={sidebarState.openedSportUrlName}
                      >
                        <Label message='view all' />
                      </SportAllLink>
                    </div>
                  ) : null}
                </React.Fragment>
              );
            }
            return (
              <SportsTreeItem
                key={s.id}
                id={s.id}
                sport={s}
                toggleOpenedSportUrlName={toggleOpenedSportUrlName}
                active={
                  s.urlName === sidebarState.openedSportUrlName
                  || s.name === sidebarState.openedSportUrlName
                }
                authenticated={authenticated}
              />
            );
          })}
        </div>
      </AnimatedDropdown>
    </>
  );
};
export default SportsSidebar;
