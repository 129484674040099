import React from 'react';
import { SportSelector } from '@apollo/core/src/state/sport/sport';
import { SiteConfigManager } from '@apollo/core';
import Label from '../../../../shared/components/I18n/Label';
import TournamentsList from './TournamentsList';

const TopTournaments = ({ limit }) => {
  const sportsCount = limit || SiteConfigManager.getConfig('config.config.sportsbook.sideBarTopSportsCount');

  const topTournaments = SportSelector.selectTopTournaments({
    limit: sportsCount,
  });
  return (
    <>
      <div className='sidebar-tree__section-title'>
        <Label message='Featured_sports' className='name' />
      </div>
      <TournamentsList tournaments={topTournaments} />
    </>
  );
};
export default TopTournaments;
